import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import BlogSummary from "../Blog/BlogSummary"

const renderTable = data => {
  return (
    <>
      <table>
        <thead>
          {data[0].map((value, index) => (
            <th key={index}>{value}</th>
          ))}
        </thead>
        <tbody>
          {data.map((row, index) => {
            if (index === 0) {
              return null
            }
            return (
              <tr>
                {row.map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            )
            return
          })}
        </tbody>
      </table>
    </>
  )
}

export const renderEmbeddedAsset = node => {
  console.log("ContentfulRichText->renderEmbeddedAsset", node)
  const { title, description, file } = node.data.target

  const mimeType = file.contentType
  const mimeGroup = mimeType.split("/")[0]

  switch (mimeGroup) {
    case "image":
      return (
        <img
          title={title ? title : null}
          alt={description ? description : null}
          src={file.url}
        />
      )
    case "video":
      return (
        <div className="embed-responsive embed-responsive-16by9">
          <video controls>
            <source src={file.url} type="video/mp4" />
            <p>Your browser doesnt support HTML5 video.</p>
          </video>
        </div>
      )
    case "application":
      return (
        <a alt={description ? description : null} href={file.url}>
          {title ? title : file.details.fileName}
        </a>
      )
    default:
      return (
        <span style={{ backgroundColor: "red", color: "white" }}>
          {mimeType} embedded asset{" "}
        </span>
      )
  }
}

export const renderHeading = node => {
  console.log(`ContentfulRichText->renderHeading`, node)
  const slug = node.content[0].value.split(" ").join("-").toLowerCase()
  const HeadingTag = `h${node.nodeType.split("-")[1]}`

  return <HeadingTag id={slug}>{node.content[0].value}</HeadingTag>
}

export const renderEmbeddedEntry = node => {
  console.log(`ContentfulRichText->renderEmbeddedEntry`, node)

  const typename = node.data.target.__typename
  switch (typename) {
    case "ContentfulTable":
      let tableName = node.data.target.name
      let tableData = node.data.target.table?.tableData ?? null

      return (
        <div>
          <h5>{tableName}</h5>
          {tableData && renderTable(tableData)}
        </div>
      )

    default:
      return <div></div>
  }
}

export const renderSummary = (node, data) => {
  console.log(`ContentfulRichText:renderSummary`)
  if (data) {
    return <BlogSummary author={data?.author} updatedAt={data?.updatedAt} />
  }
  return null
}

export default function ContentfulRichText({ content, data }) {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: renderEmbeddedAsset,
      [BLOCKS.HEADING_2]: renderHeading,
      [BLOCKS.HEADING_3]: renderHeading,
      // render blog summary
      ["blog_summary"]: node => renderSummary(node, data),
      [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry,
    },
  }
  console.log(`ContentfulRichText:content`, content)

  return (
    <div className="blog blog__content">{renderRichText(content, options)}</div>
  )

  /**
   * rich-text-react-renderer embedded assets not working
   */
  // let contentJson = JSON.parse(content.raw)
  // let html = content ? documentToHtmlString(contentJson, options) : ""

  // return (
  //   <div
  //     className="blog blog__content"
  //     dangerouslySetInnerHTML={{ __html: html }}
  //   />
  // )
}
