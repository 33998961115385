import React, { useState } from "react"
import { BLOCKS } from "@contentful/rich-text-types"

export default function BlogTopic({ topics }) {
  const [show, setShow] = useState(true)
  if (!topics || topics.length === 0) return null

  function generateSlug(text) {
    return text.split(" ").join("-").toLowerCase()
  }

  function handleToggle() {
    setShow(!show)
  }

  function renderContent() {
    return (
      <div>
        {topics.map((topic, index) => {
          if (topic.type === BLOCKS.HEADING_2) {
            return (
              <a
                key={index}
                href={`#${generateSlug(topic.value)}`}
                className="topic-list"
              >
                {topic.value}
              </a>
            )
          }
          if (topic.type === BLOCKS.HEADING_3) {
            return (
              <a
                key={index}
                href={`#${generateSlug(topic.value)}`}
                className="topic-item"
              >
                {topic.value}
              </a>
            )
          }
        })}
      </div>
    )
  }

  return (
    <div className="blog__topic">
      <h5>
        Topics On This Page{" "}
        <span onClick={handleToggle}>[{show ? "hide" : "more"}]</span>
      </h5>
      {show && renderContent()}
    </div>
  )
}
